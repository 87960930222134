export const transition = {
  default: 0.5,
  medium: 0.7,
  fast: 0.3,
  slow: 1,
};

export const color = {
  // primary: "rgba(220, 50, 50, 1)",
  primary: "rgba(255, 255, 255, 1)",
};
